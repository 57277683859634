@import "../_pacwest_colors";
@import "../_mixins";

.gray-bg{
	background: $white-accent-1;
}
#banner{
	@extend .gray-bg;
	.ibanner{
		@extend .gray-bg;
	}
	img.brand-pacwest{
		margin-left: 80px;
	}
}
.control-left-space{
	padding-right: 0;
}
#main{
	background: $white-accent-1;
	padding-top: 0;	
}
.login-form-wrapper{
	display: block;
	background: $white;
	padding: 60px;
	padding-top: 30px;
	@include border-radius(5px);	

	h2.form-title{
		color: $pacwest_blue;
		margin-bottom: 25px;
	}

}

#loginForm{
	.form-control{
		background: $white-accent-1;
		border-width: 0;
	}

	.form-group{
		 label{
			font-weight: 400;
			font-size: 16px;
			display: block;
			margin-top: 5px;
			margin-bottom: 8px;
		}
	}

	button.btn-info{
		background: $light_info;
		border-color: $light_info;
	}

	a.link{
		color: $link;
		font-size: 16px;
		display: block;
		margin-top: 15px;
	}


}


.full-width{
	width: 100%;
}


@media(max-width: 992px){
	.control-left-space{
		padding-left: 0;
		padding-right: 0;
	}
	.login-form-wrapper{
		padding: 30px;
	}

	#banner{
		img.brand-pacwest{
			margin-left: 0;
		}
	}

}	

