/*** Mixins ***/
@mixin border-radius($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	-o-border-radius: $radius;
	border-radius: $radius;
}

@mixin easeInOutAll($time){
	-moz-transition: all $time ease-in-out;
	-webkit-transition: all $time ease-in-out;
	-o-transition: all $time ease-in-out;
	transition: all $time ease-in-out;

}
